@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./font.css');

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Gilroy';
}

.header-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 01;
  overflow: hidden;
}

.bg-video__content {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.linear-style {
  background-image: linear-gradient(
    109.6deg,
    rgb(36, 45, 57, 0.8) 11.2%,
    rgb(16, 37, 60, 0.8) 51.2%,
    rgb(0, 0, 0, 0.8) 98.6%
  );
  height: 100%;
  background-repeat: no-repeat;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border-radius: 100vh;
}

body::-webkit-scrollbar-thumb {
  background-image: linear-gradient(73deg, #00469b, #002759);
  border-radius: 100vh;
}

nav {
  /*position: fixed !important;
  */
  top: 15;
  left: 0;
  width: 100%;
  z-index: 9;
  transition: transform 0.3s ease-in-out;
  /*border-bottom: 10px solid #50a200;
  */
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  -webkit-transition: transform 0.3s ease-in-out;
}

.scroll-down nav {
  top: 0;
  transform: translate3d(0, -100%, 0);
}

.scroll-up nav {
  position: fixed !important;
  top: 0;
  background-color: white;
  color: #000000;
  border-bottom: 1px solid #eee;
  /* border-bottom: 10px solid #50a200; */
}

.cut-off-text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.counter-text-color {
  background: -webkit-linear-gradient(180deg, #45ba60, #2581c4);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.arrow-animation {
  animation: leftanimation 5s linear infinite;
  -webkit-animation: leftanimation 5s linear infinite;
}

.text-shadow {
  text-shadow: 1.5px 1.5px black;
}

@keyframes leftanimation {
  0% {
    transform: translateX(-15px);
    -webkit-transform: translateX(-15px);
    -moz-transform: translateX(-15px);
    -ms-transform: translateX(-15px);
    -o-transform: translateX(-15px);
  }
  50% {
    transform: translateX(15px);
    -webkit-transform: translateX(15px);
    -moz-transform: translateX(15px);
    -ms-transform: translateX(15px);
    -o-transform: translateX(15px);
  }
  100% {
    transform: translateX(-15px);
    -webkit-transform: translateX(-15px);
    -moz-transform: translateX(-15px);
    -ms-transform: translateX(-15px);
    -o-transform: translateX(-15px);
  }
}

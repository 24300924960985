@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./assets/fonts/Gilroy-Regular.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./assets/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./assets/fonts/Gilroy-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./assets/fonts/Gilroy-Bold.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./assets/fonts/Gilroy-ExtraBold.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
